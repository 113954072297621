import React from "react";

import UserData from "../Types/UserData";
import { getUserScore } from "../Lib/CompareUtils";
import { generateGroupsGrid, generateSingleList } from "../Utils";
import { MedalDataWithAcquired } from "../Types/MedalTypes";
import ProfileLinks from "./ProfileLinks";
import useMedalsData from "../Hooks/useMedalsData";
import Flag from "./Flag";
import { useSupporters } from "../Hooks/useSupporters";
import Username from "./Username";

type Props = {
  userData: UserData;
  userMedals: MedalDataWithAcquired[];
};

const ProfileHeader: React.FC<Props> = ({ userData, userMedals }) => {
  const { score } = getUserScore(userData);
  const supporters = useSupporters();
  const { gradedMedals } = useMedalsData();
  const userProgress = Math.round(score * 100 * 100) / 100;

  const shareGradedClicked = () => {
    navigator.clipboard
      .writeText(
        `${userData?.name}\n` +
          `Rank: ${userData?.rank}\n` +
          `TM Score: ${isNaN(userProgress) ? "-" : userProgress}%\n\n` +
          generateGroupsGrid(
            Object.values(gradedMedals),
            userData!.medals,
            "🟩",
            "⬜"
          ).join("") +
          generateSingleList(userMedals) +
          `\nhttps://turfmedals.com/profile/${encodeURIComponent(
            userData!.name
          )}`
      )
      .then(() => {
        alert("Stats copied to your clipboard ready to be pasted");
      });
  };

  const isSupporter =
    supporters.findIndex((s) => s.name === userData.name) !== -1;

  return (
    <div className="header-wrapper mb-3">
      <div className="header-title">
        <div className="header-profile">
          {userData.country && <Flag country={userData.country} />}
          <span className="ml-1">
            <Username username={userData.name} supporters={supporters} />
          </span>
        </div>
        <button
          className="btn btn-primary"
          onClick={shareGradedClicked}
          title="Copied to your clipboard (like Wordle), ready to be pasted!"
        >
          Share stats
        </button>
      </div>
      <div className="header-region">{userData?.region?.name || ""}</div>
      {userData.name && (
        <ProfileLinks userid={userData.id} username={userData.name} />
      )}
    </div>
  );
};

export default React.memo(ProfileHeader);
