import { useEffect, useState } from "react";
import { Route, Routes, useMatch, useNavigate } from "react-router-dom";
import { Page } from "tabler-react";

import "tabler-react/dist/Tabler.css";
import "react-popper-tooltip/dist/styles.css";

import UserData from "./Types/UserData";
import { MedalDataWithAcquired } from "./Types/MedalTypes";
import { LAST_VIEWED_PROFILE_KEY } from "./Lib/Constants";
import {
  fetchTurfgameUser,
  fetchTurfportalenUser,
} from "./Services/UserService";

import About from "./Containers/About";
import Compare from "./Containers/Compare";
import Daily from "./Containers/Daily";
import Links from "./Containers/Links";
import Profile from "./Containers/Profile";
import Search from "./Containers/Search";
import SiteWrapper from "./Containers/SiteWrapper";
import Top from "./Containers/Top";
import Feed from "./Containers/Feed";
import useMedalsData from "./Hooks/useMedalsData";

function App() {
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [userData, setUserData] = useState<UserData | null>(null);
  const [notFoundError, setNotFoundError] = useState<boolean>(false);

  const [allSingleMedals, setAllSingleMedals] = useState<
    MedalDataWithAcquired[]
  >([]);
  const [userSingleMedals, setUserSingleMedals] = useState<
    MedalDataWithAcquired[]
  >([]);
  const [userMissingMedals, setUserMissingMedals] = useState<
    MedalDataWithAcquired[]
  >([]);
  const profileMatch = useMatch("/profile/:userName");
  const navigate = useNavigate();
  const { singleMedals } = useMedalsData();

  useEffect(() => {
    if (profileMatch) {
      const { userName } = profileMatch.params;
      if (
        userName &&
        userData?.name?.toLocaleLowerCase() !== userName.toLocaleLowerCase()
      ) {
        getData(decodeURIComponent(userName));
      }
    }
  }, [profileMatch]);

  const updateUserData = (user: UserData | null) => {
    setUserData(user);
    if (user != null && singleMedals != null) {
      const allSingleMedalsWithAcquired: MedalDataWithAcquired[] =
        Object.values(singleMedals).map((m) => ({
          ...m,
          acquired: user.medals.includes(m.id),
        }));

      setUserSingleMedals(
        allSingleMedalsWithAcquired.filter((m) => m.acquired)
      );
      setUserMissingMedals(
        allSingleMedalsWithAcquired.filter((m) => !m.acquired)
      );
      setAllSingleMedals(allSingleMedalsWithAcquired);
      navigate(`/profile/${encodeURIComponent(user.name)}`);
      document.title = `${user.name} - Turf Medals`;
      localStorage.setItem(LAST_VIEWED_PROFILE_KEY, user.name);
    } else {
      navigate("/");
    }
  };

  const onFetchClicked = () => {
    if (!username) return;

    getData(username);
  };

  const getData = (name: string) => {
    setNotFoundError(false);
    if (userData !== null && name !== userData.name) {
      setUserData({ ...userData, name: "", country: null });
    }
    setLoading(true);
    fetchTurfgameUser(name)
      .then((user: UserData) => {
        const medals = user.medals.slice();
        updateUserData(user);
        fetchTurfportalenUser(user.name).then((portalenUser) => {
          if (portalenUser) {
            updateUserData({
              ...user,
              medals, // workaround or else it reorders them..
              date_month: portalenUser.date_month,
              round: portalenUser.round,
              round_unique: portalenUser.round_unique,
            });
          }
        });
      })
      .catch((error: Error) => {
        if (error?.message?.indexOf("not found")) {
          setNotFoundError(true);
        }
        updateUserData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="App">
      <SiteWrapper
        hasData={userData != null}
        onFetchClicked={onFetchClicked}
        setUsername={setUsername}
        username={username}
      >
        <Page.Content>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Search
                    onFetchClicked={onFetchClicked}
                    setUsername={setUsername}
                    username={username}
                  />
                  {!loading && notFoundError && (
                    <div className="alert alert-danger mt-6" role="alert">
                      Turfer not found 🤷🏼‍♂️
                    </div>
                  )}
                </>
              }
            />
            <Route
              path="/profile/:userName"
              element={
                <Profile
                  allMedals={allSingleMedals}
                  loading={loading}
                  userData={userData}
                  userSingleMedals={userSingleMedals}
                  userMissingMedals={userMissingMedals}
                />
              }
            />
            <Route path="/about" element={<About />} />
            <Route path="/compare" element={<Compare />} />
            <Route path="/daily" element={<Daily />} />
            <Route path="/feed" element={<Feed />} />
            <Route path="/links" element={<Links />} />
            <Route path="/top" element={<Top />} />
          </Routes>
        </Page.Content>
      </SiteWrapper>
    </div>
  );
}

export default App;
