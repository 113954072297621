import { usePopperTooltip } from "react-popper-tooltip";
import classnames from "classnames";

import {
  MedalData,
  MedalDataWithGroupData,
  MedalGroup,
} from "../Types/MedalTypes";
import useSingleTrackedMedal from "../Hooks/useSingleTrackedMedal";
import { GetMedalGroupForMedal, GetMedalName } from "../Lib/Medals";
import useMedalsData from "../Hooks/useMedalsData";
import { TOTAL_USERS } from "../Lib/Constants";
import { getUserScore } from "../Lib/CompareUtils";
import UserData from "../Types/UserData";
import {
  CurrentData,
  getCurrentDataForMedal,
  getCurrentDataForMedalGroup,
} from "../Utils";

export type MedalItemProps = {
  acquired?: boolean;
  disableTooltip?: boolean;
  hideAcquiredStatus?: boolean;
  medal: MedalData | MedalDataWithGroupData;
  medalGroup?: MedalGroup;
  name?: string;
  size?: "small" | "medium";
  userData?: UserData;
};

const MedalItem: React.FC<MedalItemProps> = (props) => {
  const {
    acquired = false,
    disableTooltip = false,
    hideAcquiredStatus = false,
    medal,
    medalGroup: _medalGroup,
    name = null,
    size = "medium",
    userData,
    ...rest
  } = props;
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ interactive: true, trigger: "click" });
  const { tracked, toggleTracked, username } = useSingleTrackedMedal(medal?.id);
  const { turfMedalsData } = useMedalsData();

  if (!medal) return null;
  const hasUserData = userData != null;
  const medalValue =
    medal.value && !Number.isNaN(medal.value) ? parseInt(medal.value, 10) : 0;
  const medalGroup = _medalGroup ?? GetMedalGroupForMedal(medal);

  const scoreAddition =
    !acquired && hasUserData
      ? {
          score: getUserScore(userData).score,
          scoreWithMedal: getUserScore({
            ...userData,
            medals: [...userData.medals, medal.id],
          }).score,
        }
      : null;

  const displayName = name ?? GetMedalName(medal as MedalDataWithGroupData);
  let currentData: CurrentData | null = null;
  if (hasUserData) {
    currentData = medalGroup
      ? getCurrentDataForMedalGroup(medalGroup, userData)
      : getCurrentDataForMedal(medal, userData);
  }

  const renderStatus = () => {
    const count =
      acquired && userData?.medals
        ? userData.medals.filter((m) => m === medal.id).length
        : 0;
    return (
      <span className={acquired ? "avatar-status bg-green" : ""}>
        <div>{count > 1 && count}</div>
      </span>
    );
  };

  const hasHoldersData =
    turfMedalsData && turfMedalsData[medal.id]?.holders != null;
  const holders = hasHoldersData ? turfMedalsData[medal.id].holders! : 0;
  const totalHolders = hasHoldersData
    ? turfMedalsData[medal.id].totalHolders!
    : 0;

  const totalHoldersDifferentFromHolders = totalHolders !== holders;

  return (
    <span
      className={classnames("medal-item-wrapper", {
        "bg-yellow tracked": tracked,
      })}
    >
      <span
        className={classnames("avatar  m-1 pointer", {
          "avatar-xl": size === "medium",
          "avatar-l": size === "small",
        })}
        ref={setTriggerRef}
        style={{
          backgroundImage: `url("https://turfgame.com/images/medals/${medal.id}.png")`,
        }}
        {...rest}
      >
        {!hideAcquiredStatus && renderStatus()}
      </span>
      {visible && !disableTooltip && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          <h5>
            {displayName}
            {!acquired && hasUserData && scoreAddition && (
              <sup className="ml-1 text-green">
                <abbr
                  className="abbr"
                  title="Estimate on how the TM Score will increase if this medal is achieved. Please note that this is only for the medal, not any additional points, rank etc. you achieve that also add to your TM Score."
                >
                  +
                  {Math.round(
                    (scoreAddition.scoreWithMedal - scoreAddition.score) *
                      1000 *
                      100
                  ) / 1000}
                  %pt
                </abbr>
              </sup>
            )}
          </h5>

          {hasUserData && currentData?.value && (
            <div className="mb-1">
              <strong>
                Current{" "}
                {currentData.entity
                  ? currentData.entity +
                    (currentData.value !== 1 &&
                    currentData.entityPluralize !== false
                      ? "s"
                      : "")
                  : null}
                :{" "}
              </strong>
              {currentData.value?.toLocaleString() || "-"}
              {currentData.desc && <abbr title={currentData.desc}> *</abbr>}
              {medalValue > 0 && (
                <>
                  <br />
                  <strong>Required: </strong>
                  {medalValue.toLocaleString() || "-"}
                </>
              )}
            </div>
          )}

          {medal.description && <div>{medal.description}</div>}

          {medal.tip && !acquired && (
            <div className="mt-4">
              <strong>Tip:</strong>{" "}
              <span dangerouslySetInnerHTML={{ __html: medal.tip }}></span>
            </div>
          )}

          {hasHoldersData && (
            <div className="mt-4">
              <strong
                title={
                  totalHoldersDifferentFromHolders
                    ? "Current holders + those that also qualify for it by having a higher graded medal"
                    : ""
                }
              >
                Total holders:
              </strong>{" "}
              {totalHolders}
              {holders > 0 && (
                <span>
                  {" "}
                  ({((totalHolders * 100) / TOTAL_USERS).toFixed(2)}%){" "}
                </span>
              )}
              <br />
              {totalHoldersDifferentFromHolders && (
                <>
                  <strong>Current holders:</strong> {holders}
                </>
              )}
            </div>
          )}

          {medal.takeTypes && (
            <div className={!hasHoldersData ? "mt-4" : undefined}>
              <abbr title="Which types of takes that count towards this medal">
                <strong>Take types: </strong>
              </abbr>
              {medal.takeTypes.join(", ")}
            </div>
          )}

          <div className="mt-4">
            <strong>More info:</strong>{" "}
            {medal.wikiLink && (
              <>
                <a
                  href={medal.wikiLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Wiki
                </a>
                {" | "}
              </>
            )}
            <a
              href={`https://warded.se/turf/medals.php?medalid=${medal.id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Warded
            </a>
          </div>

          {username &&
            (tracked ? (
              <button onClick={toggleTracked}>Unpin {displayName}</button>
            ) : (
              <button
                onClick={toggleTracked}
                title="Click to pin this medal. It will then highlight for you when you visit this specific profile. As an example you can use it to mark the medals you are hoping to accomplish next."
              >
                Pin {displayName}
              </button>
            ))}

          {medalGroup && (
            <div className="mt-4">
              <h4>All Grades:</h4>
              {medalGroup.medals.map((m) => (
                <div
                  key={m.id}
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MedalItem
                    acquired={userData?.medals?.includes(m.id)}
                    disableTooltip
                    medal={m}
                    name={m.name}
                    userData={userData}
                  />
                  <span className="pl-2">
                    {m.descriptionShort || m.name || m.value || "-"}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </span>
  );
};

export default MedalItem;
