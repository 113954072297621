import {
  MedalData,
  MedalDataWithAcquired,
  MedalGroup,
} from "./Types/MedalTypes";
import UserData from "./Types/UserData";

export type CurrentData = {
  desc?: string;
  entity?: string;
  entityPluralize?: boolean;
  text?: string;
  value?: number;
};

export const hasUserMedal = (id: number, userMedals: number[]) => {
  if (userMedals) {
    return userMedals.includes(id);
  }

  return false;
};

export const getCurrentMedal = (
  medalGroup: MedalGroup,
  userMedals: number[]
) => {
  return medalGroup.medals.filter((m) => hasUserMedal(m.id, userMedals))?.pop();
};

export const getNextMedal = (medalGroup: MedalGroup, userMedals: number[]) => {
  const { discontinued, medals } = medalGroup;
  if (discontinued) {
    return null;
  }

  const index = medals
    .map((m) => hasUserMedal(m.id, userMedals))
    .lastIndexOf(true);

  if (index < medals.length) {
    return medals[index + 1];
  }
  return null;
};

export const getCurrentDataForMedal = (
  medal: MedalData,
  userData: UserData
): CurrentData | null => {
  switch (medal.name) {
    case "Gold Medal":
    case "Silver Medal":
    case "Bronze Medal":
      return {
        value: userData.place,
        entity: "position",
        entityPluralize: false,
      };
  }

  return { text: "-" };
};

export const getCurrentDataForMedalGroup = (
  medalGroup: MedalGroup,
  userData: UserData,
  completed = false
): CurrentData | null => {
  switch (medalGroup.name) {
    case "Take":
      return { value: userData.taken, entity: "take" };

    case "Greed":
      return { value: userData.zones.length, entity: "zone" };

    case "Region Star":
      return { value: userData.regionsVisited, entity: "region" };

    case "Unique":
      return { value: userData.uniqueZonesTaken, entity: "unique" };

    case "Diverse": {
      if (
        userData.round_unique !== undefined &&
        !isNaN(userData.round_unique)
      ) {
        return {
          desc: "Source: Turfportalen.se",
          entity: "unique",
          value: userData.round_unique,
        };
      }
      break;
    }

    case "Roundpointer":
      return { value: userData.points, entity: "point" };

    case "Donation":
      return { text: "-" };

    case "Daily": {
      const value = completed
        ? userData.daily?.record
        : userData.daily?.current;
      if (value) {
        return {
          desc: "Source: turfbridge.com/rip.php. Does not include today (Europe time, and could at certain times be an additional day off)! Should be considered an estimate.",
          entity: "day",
          value,
        };
      }
    }
  }

  const next = getNextMedal(medalGroup, userData.medals);
  if (next && next.descriptionShort) {
    return { text: next.descriptionShort };
  }
  return { text: "-" };
};

export const getProgressUntilNext = (
  userPoints: number,
  curRank: number,
  nextRank: number
) => {
  if (!nextRank) return 0;

  const diff = nextRank - curRank;
  const userProgress = userPoints - curRank;

  return Math.round((100 * userProgress) / diff);
};

export const generateGroupsGrid = (
  medalGroups: MedalGroup[],
  userMedals: number[],
  checkedTile: string,
  unCheckedTile: string
) => {
  return medalGroups.map((mg) => {
    const grid = mg.medals.map((m, i) =>
      hasUserMedal(m.id, userMedals) ? checkedTile : unCheckedTile
    );
    return `${mg.name}\n${grid.join(" ")}\n`;
  });
};

export const generateSingleList = (userMedals: MedalDataWithAcquired[]) => {
  return `Other\n` + userMedals.map((m) => m.name).join(", ");
};
