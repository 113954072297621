import React from "react";

type Props = {
  small?: boolean;
};
const DonateButton: React.FC<Props> = ({ small = false }) => (
  <div className="mb-1">
    <a href="https://ko-fi.com/N4N1VWRUQ" target="_blank">
      <img
        alt="Buy Me a Coffee at ko-fi.com"
        src="https://storage.ko-fi.com/cdn/kofi2.png?v=3"
        style={{ border: "0px", height: small ? "36px" : "50px" }}
      />
    </a>
  </div>
);
export default DonateButton;
