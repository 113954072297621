import { get, ref } from "firebase/database";

import UserData, { FirebaseUserData } from "../Types/UserData";
import { db } from "./FirebaseDB";

const BASE_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "https://api.turfgame.com"
    : "https://apiturf.mitt.land";

const PORTALEN_BASE_URL = "https://apiturfportalen.mitt.land";

export const fetchTurfgameUser = async (name: string): Promise<UserData> => {
  const response = await fetch(`${BASE_URL}/private/users`, {
    body: JSON.stringify([{ name: name?.replace(/ /g, "") }]),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });

  if (!response.ok) {
    throw new Error("Failed to fetch user data");
  }

  const [user] = await response.json();

  if (!user) {
    throw new Error("User not found");
  }

  const rankRef = ref(db, `ranks/${user.id}`);
  const rankSnapshot = await get(rankRef);
  const rank = rankSnapshot.val();

  const turferRef = ref(db, `turfers/${user.id}`);
  const turferSnapshot = await get(turferRef);
  const turfer: FirebaseUserData | null = turferSnapshot.val();

  // Augment the user data with firebase data
  return { ...user, TMRank: rank || null, daily: turfer?.daily };
};

export const fetchTurfportalenUser = async (name: string): Promise<any> => {
  const response = await fetch(
    `${PORTALEN_BASE_URL}/api/player/${encodeURIComponent(
      name.replace(/ /g, "")
    )}`
  );

  if (!response.ok) {
    console.warn(
      "Could not get from Turfportalen, but resolve user from turfgame"
    );
    return null;
  }

  const [portalenUser] = await response.json();

  return portalenUser;
};
