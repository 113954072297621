import React, { useEffect } from "react";
import { Card } from "tabler-react";
import DonateButton from "../Components/Cards/DonateButton";
import { AmountTitles, GetAmountAttributes } from "../Lib/SupporterUtils";
import { useSupporters } from "../Hooks/useSupporters";

type Props = {};

const About: React.FC<Props> = () => {
  useEffect(() => {
    document.title = `About - Turf Medals`;
  }, []);
  const supporters = useSupporters();

  return (
    <div>
      <Card className="p-4">
        <div>
          <h3>About</h3>
          As a new Turfer I found the official Turf profile page to leave some
          things to be desired, especially when it came to knowing which medals
          I had, which I was missing and which were even possible to accomplish
          (without quitting my day job).
          <br />
          <br />
          So I hacked together a quick profile page based on the Turf API, and
          decided to share it.
          <br />
          <br />
          <h3>Is there a mobile app version?</h3>
          Yes, you can install it as an app on your mobile:
          <br />
          <br />
          <strong>Android</strong>: Open this site in Chrome, press the "three
          dot" icon in the upper right to open the menu. Select "Add to Home
          screen", and press the "Add" button in the popup. <br />
          <strong>iOS</strong>: Open this site <strong>in Safari</strong>{" "}
          (important), choose the Share up-arrow at the bottom of the screen,
          and select Add to Home Screen.
          <br />
          <br />
          <br />
          <h3 id="Contribute">Can I contribute in some way?</h3>
          Yes, you can help me with the following:
          <br />
          <ul>
            <li>Spread the word about the site on forums, social media etc.</li>
            <li>
              Suggest new things on the{" "}
              <a href="https://forum.turfgame.com/viewtopic.php?t=17464">
                Turf forum thread
              </a>
              .
            </li>
            <li>
              I have a family and create this stuff in my spare time. If you
              want to keep me motivated and help with the hosting fees (and
              perhaps a beer after a long day of turfing & coding), feel free to
              send me a few bucks. <br />
              Your turf name will be{" "}
              <span className="donator-highlight">highlighted</span> on the page
              as a thank you :)
            </li>
          </ul>
          <DonateButton />
          <br />
          <br />
          <h3 id="Changelog">Changelog</h3>
          <ul>
            <li>
              <strong>8th of June, 2024</strong>: Add{" "}
              <a href="/daily">Top Daily</a>.
            </li>
            <li>
              <strong>5th of June, 2024</strong>: Add Daily numbers and progress
              thanks to turfbridge.com/rip.php
            </li>
            <li>
              <strong>3rd of June, 2024</strong>: Display and use "Total
              Holders" (current holders + those that also qualify for it by
              having a higher graded medal) when calculating the easiest and
              most obscure medals a turfer has.
            </li>
            <li>
              Changelog before 3rd of June, 2024:{" "}
              <a href="https://forum.turfgame.com/viewtopic.php?t=17464">
                https://forum.turfgame.com/viewtopic.php?t=17464
              </a>
            </li>
          </ul>
          <br />
          <br />
          <h3 id="Supporters">
            List of <a href="https://ko-fi.com/N4N1VWRUQ">supporters</a> (thank
            you!):
          </h3>
          <ul>
            {supporters.map((supporter) => {
              const { className, title } = GetAmountAttributes(
                supporter.amount
              );
              return (
                <li key={supporter.name}>
                  <a
                    href={`/profile/${supporter.name}`}
                  >{`${supporter.name}`}</a>
                  <span
                    className={className}
                    title={title}
                  >{` ${supporter.amount}`}</span>
                </li>
              );
            })}
          </ul>
          <br />
          <h4>Donation levels</h4>
          <ul>
            {Object.entries(AmountTitles).map(([key, value]) => (
              <li key={key}>
                <span className={GetAmountAttributes(key as any).className}>
                  {key}:
                </span>{" "}
                {value}
              </li>
            ))}
          </ul>
          The amount is the total sum if a supporter donates multiple times.
          <br />
          <br />
          <h3>
            The <em>Turf Medals Score</em>
          </h3>
          The <em>TM Score</em> displayed on the page gives an unscientific
          total progress/score estimate for a turfer based on most of the stats
          I display on the site. New turfers might get a higher score than what
          they deserve, but that is by design, and for the absolute top turfers,
          it's about getting those final few, few percentages.
          <br />
          <br />
          <h3>Feedback</h3>
          Please use the{" "}
          <a href="https://forum.turfgame.com/viewtopic.php?t=17464">
            Turf forum thread
          </a>
          .
          <br />
          <br />
          <h3>Credits</h3>
          <a href="https://turfportalen.se">Turfportalen.se</a> for some data.
          Thanks, <a href="/profile/laynus">Laynus</a>!
          <br />
          <br />
          <a href="https://turfbridge.com">Turfbridge.com</a> for daily data.
          Thanks, <a href="/profile/DIProgan">DIProgan</a>!
          <br />
          <br />
          Logo by{" "}
          <a
            className="text-muted"
            href="https://thenounproject.com/icon/profile-1934670/"
          >
            Tienan
          </a>
        </div>
      </Card>
    </div>
  );
};

export default React.memo(About);
